import React from 'react';
import {
    Wrapper,
    HeadingContainer,
    HeadingText,
    Logo,
    Container,
    SubContainer,
    BannerHeader,
    HeaderImg,
    BodySection,
    InfoBox,
    Heading,
    PointsContainer,
    Bullet,
    BulletIconContainer,
    Text,
} from './marketplaceAuthenticate.style';
import AuthenticateLogo from '../../../../images/logos/authenticate_rgb.svg';
import MarketplaceIllustration from '../../../../images/product-assets/marketplace-auth-illustration.svg';
import { MARKETPLACE_AUTHENTICATE_DATA } from '../../utilities';
import CheckMarkIcon from '../../../../images/Icons/icon-checkmark-styled.svg';

function MarketplaceAuthenticate() {
    return (
        <Wrapper>
            <Container>
                <HeadingContainer>
                    <HeadingText>How Marketplaces</HeadingText>
                    <Logo src={AuthenticateLogo} alt="Authenticate Logo" />
                </HeadingContainer>
                <SubContainer>
                    <BannerHeader>
                        <HeaderImg src={MarketplaceIllustration} alt="Marketplace banner" />
                    </BannerHeader>
                    <BodySection>
                        {MARKETPLACE_AUTHENTICATE_DATA.map((info) => (
                            <InfoBox key={info.id}>
                                <Heading>{info.title}</Heading>
                                <PointsContainer>
                                    {info?.points?.map((point) => (
                                        <Bullet key={point}>
                                            <BulletIconContainer>
                                                <img src={CheckMarkIcon} alt="check-mark-icon" />
                                            </BulletIconContainer>
                                            <Text>{point}</Text>
                                        </Bullet>
                                    ))}
                                </PointsContainer>
                            </InfoBox>
                        ))}
                    </BodySection>
                </SubContainer>
            </Container>
        </Wrapper>
    );
}

export default MarketplaceAuthenticate;
