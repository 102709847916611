import React from 'react';
import RootLayout from '../../layouts/RootLayout';
import HeroSection from '../../sections/solutions/components/HeroSection';
import HERO_IMG from '../../images/product-assets/marketplace-page-hero-image.svg';
import Vendors from '../../components/reusable/vendors/Index';
import MarketplaceAuthenticate from '../../sections/Industries/marketplaces/MarketplaceAuthenticate';
import FraudPrevention from '../../sections/Industries/financial-institutions/FraudPrevention';
import {
    MARKETPLACE_FRAUD_PREVENTIONS,
    MARKETPLACE_FRAUDS,
    MARKETPLACE_PAGE_FAQS,
    MARKETPLACES_PAGE_RESOURCES,
} from '../../sections/Industries/utilities';
import IdentityFrauds from '../../sections/Industries/financial-institutions/IdentityFrauds';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import BottomBanner from '../../components/reusable/BottomBanner';
import { SAFETY_SECURITY_BANNER_CONTENTS } from '../../helper/constant';
import Resources from '../../components/reusable/Resources';
import MetaTagsProvider from '../../components/MetaTagsProvider';

function marketplaces() {
    return (
        <RootLayout getStartedCtaLink="https://portal.authenticate.com/register?utm_source=industry&utm_medium=marketplace&utm_campaign=navbar">
            <MetaTagsProvider
                title="Authenticate: Instant Identity Verification and Background Checks for Marketplaces"
                description="Authenticate empowers e-commerce businesses and marketplaces with industry-leading identity verification services designed to boost conversion rates, enhance security, and ensure fast onboarding across retail and marketplace platforms. With seamless integration, it maximizes trust and user satisfaction."
                keywords="Authenticate, instant onboarding, identity verification, marketplaces, user trust, business growth, regulatory compliance, fraud prevention, transaction security, kyc, aml, kyc compliance, background checks"
                path="industries/marketplaces"
                ogTitle="Authenticate: Instant Onboarding and Identity Verification for E-Commerce and Marketplaces"
                ogDescription="Enhance your marketplace with Authenticate® by implementing cutting-edge identity verification solutions that support growth by ensuring security, compliance, and user satisfaction."
                twitterTitle="Authenticate: Instant Onboarding and Identity Verification for E-Commerce and Marketplaces"
                twitterDescription="Enhance your marketplace with Authenticate® by implementing cutting-edge identity verification solutions that support growth by ensuring security, compliance, and user satisfaction."
                ogImage="https://cdn.authenticating.com/public/website/industries/marketplace/og-image-marketplace.jpg"
            />
            <HeroSection
                category="Industries"
                pageTitle="MARKETPLACE"
                heroImage={HERO_IMG}
                heading="Marketplace Identity Verification Made Simple"
                getStartedCtaLink="https://portal.authenticate.com/register?utm_source=industry&utm_medium=marketplace&utm_campaign=hero"
                subHeading="Leverage instant identity verification and AI-powered risk mitigation to effortlessly onboard users while enhancing security. Increase conversions and reduce drop-offs by providing a seamless customer experience."
            />
            <Vendors />
            <MarketplaceAuthenticate />
            <IdentityFrauds
                heading="42% of E-Commerce Fraud happens in North America"
                whitepaperPath="/whitepapers/marketplace"
                dataOne={MARKETPLACE_FRAUDS.DATA_1}
                dataTwo={MARKETPLACE_FRAUDS.DATA_2}
            />
            <FraudPrevention
                cardOne={MARKETPLACE_FRAUD_PREVENTIONS.DATA_1}
                cardTwo={MARKETPLACE_FRAUD_PREVENTIONS.DATA_2}
            />
            <Resources item={MARKETPLACES_PAGE_RESOURCES} />
            <ProductFaq
                faqData={MARKETPLACE_PAGE_FAQS}
                subHeading="Everything You Need to Know About Fraud Protection for Marketplaces."
                animation
                titleColor="#262626"
            />
            <BottomBanner
                data={SAFETY_SECURITY_BANNER_CONTENTS}
                getStartedCtaLink="https://portal.authenticate.com/register?utm_source=industry&utm_medium=marketplace&utm_campaign=footer"
            />
        </RootLayout>
    );
}

export default marketplaces;
