import styled from 'styled-components';
import { breakpoints } from '../../../../styles/screenSizes';
import MarketplaceBg from '../../../../images/product-assets/marketplace-auth-bg.png';

export const Wrapper = styled.section`
    margin-top: 102px;

    @media only screen and (max-width: ${breakpoints.sm}) {
        margin-top: 60px;
    }
`;

export const Container = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 0px 24px;

    @media only screen and (max-width: ${breakpoints.sm}) {
        padding: 0px 16px;
    }
`;

export const HeadingContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 6px;
    margin-bottom: 64px;

    @media only screen and (max-width: ${breakpoints.md}) {
        align-items: center;
        flex-direction: column;
        margin-bottom: 44px;
    }
`;

export const HeadingText = styled.h1`
    font-size: 42px;
    color: #262626;
    font-weight: 500;
    line-height: 50.4px;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 30px;
        line-height: 36px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        font-size: 24px;
        line-height: 28.8px;
    }
`;

export const Logo = styled.img`
    height: 32px;
    width: auto;
    margin-bottom: 10px;

    @media only screen and (max-width: ${breakpoints.md}) {
        height: 24px;
        margin-bottom: 0px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        height: 20.91px;
    }
`;

export const SubContainer = styled.div`
    margin-top: 64px;
    border: 1px solid #e8e8e8;
    border-radius: 38px;
    overflow: hidden;

    @media only screen and (max-width: ${breakpoints.md}) {
        border-radius: 20px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        border-radius: 16px;
    }
`;

export const BannerHeader = styled.div`
    max-height: 560px;
    background: url(${MarketplaceBg}) no-repeat center center/cover;
    padding: 24px 24px 0;
    overflow: hidden;
`;

export const BodySection = styled.div`
    border-top: 1px solid #e8e8e8;
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: ${breakpoints.sm}) {
        flex-direction: column;
    }
`;

export const InfoBox = styled.div`
    padding: 32px 20px;
    flex-grow: 1;
    border-right: 1px solid #e8e8e8;

    &:last-child {
        border-right: none;
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        padding: 16px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        border-bottom: 1px solid #e8e8e8;
        border-right: none;

        &:last-child {
            border-bottom: none;
        }
    }
`;

export const HeaderImg = styled.img`
    margin: 0 auto;
    width: 100%;
    height: auto;
`;

export const Heading = styled.h2`
    font-family: Rubik, sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 31.2px;
    color: #1d2939;
    margin: 18px 10px 0;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 14px;
        line-height: 18.2px;
        margin: 0;
    }
`;

export const PointsContainer = styled.div`
    margin-top: 20px;
    padding: 0 10px;

    @media only screen and (max-width: ${breakpoints.md}) {
        padding: 0;
    }
`;

export const Bullet = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        margin-bottom: 12px;
    }
`;

export const BulletIconContainer = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #e0f2fe;
    display: grid;
    place-items: center;
    flex-shrink: 0;

    > img {
        filter: invert(39%) sepia(24%) saturate(3375%) hue-rotate(170deg) brightness(95%)
            contrast(102%);
    }
`;

export const Text = styled.p`
    font-family: Rubik, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: #475467;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 12px;
        line-height: 16.8px;
    }
`;
